import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './Ps.scss';
import Psdifferences from '../../../../assets/img/psdifferences.png';
import Can from '../../../../assets/img/can.png';
import Services from '../../../../assets/img/services.png';
import Xboxdifferences from '../../../../assets/img/xboxdifferences.png';
import Xboxgames from '../../../../assets/img/xboxgames.png';

function Ps() {

    const psData = [
        {
            id: 1,
            title: "Playstation 4 and 5: Similarities and Differences",
            text: "Varieties in the field of video and PlayStation have emerged into immense convertations of the field thus state-of-the-art diversity greatly differ from the previous ones. PlayStation 4 and 5 are upgrading genres of the preceding forms thus five is of characteristics that 4 is deprived of. Next generation console becomes predictive and imperative for amendments, accommodations. Cursory glance reveals the main features including possibility of storage, fast operation means. The capabilities of the new-generation platforms underlines its multi-functionality in different means. The architectural solutions of the latter has resulted in transistor consistency as well. The clock speed has also taken rotates though taking more power than previously. Technical reveals emphasized the performance inequality referring the positive meaning. System power budget is accompanied with higher frequency. Massively upgrading are supposedly more expensive and of larger capacity and bigger mechanical disk. Bottleneck is the capability of upgrading the storage and enlarging assets.",
            img: Psdifferences,
        },
        {
            id: 2,
            title: "Can PS4 Games Be Played on PS5?",
            text: "Yes, backward compatibility of PS5 permit successfully implements  with no extra obstacles. They are playable almost without exceptions. Boosting results in functionalities  available in both varieties without errors of unexpected behavior. Current console the more are options governing controlling set-ups of drawbacks. Handiness of discs allow updating the present-day category by inserting it and selecting hubs. Important thing is to use the same account in both manifestations; the access compensates the storage from extra unnecessary material. Connectedness to the same network is of essentiality meanwhile. Owning avant-garde headset easily ensures plugging in the device processing its usage. Physical and digital romps are available with new updated-versions. Frame rate likelihood, restrainer, and remote take-part-ins have embellished  closer in distance. Borderlands, Destiny, Dirt are obtainable  in both models. Publisher’s support enhances dependability of upgrades. Courtesy being typical to two models excludes redundant efforts in renewals. Purchase of physical copies are also common in both expositions.",
            img: Can,
        },
        {
            id: 3,
            title: "The Best and High Quality of  Services: All You Need to Know",
            text: "New generation of sticking distractions  have evolved into a first-party suggestions coming up with modern requirements with supplements lining up with the highest refresh rates. Designs are changed accordingly loading the speed effectively smothering the process of accommodation. Graphic implementation is another approach rising interest in renewed embodiment of preceding diversities. Delivering awesome power ensures incredible entertainment due to the vibrant technology with affordable prices. Acclaimed frolics  should never be missed thanks so iconic interchangeability. Freedom enhances socialization between users  worldwide. Hereness of parental control is another plus keeping young people as safe as possible. Streaming services also suggest subscriptions. Entertainment becomes a means of sale. Requirement of parental consent (except users above 18) apply legal measurements. Individual process of audio materials adds pluses to the functionality. Technical specifications allows full comparisons following advancement, development. Blisteringly speeding advantages represent various showcases grabbing competition.",
            img: Services,
        },
        {
            id: 4,
            title: "Differences between Xbox and PS4/5",
            text: "Two versions successfully round the top rates  of original Xbox games, initial fears of their miner non-advancing transposition has led to internal twinks. Performance and  experience have evolved option substitutes in menu looking vast and realistic. Gulf in representation evidence created-to-satisfy-new-generation methodology. Accessibility of headphones in the latter version scotched those rumors of their non-amiable usage. Streaming apps graced  more wide-known.  Considering user term experiences  sharing with PS4 is diving of a top-modern system serving next-gens. However, it is bizarrely projected to underline the distinctions not in a positive sense of it. With super-stylish home screen ideally find out the methods of using the layout. Snappy operation is past now, usability aids via logical iconic designation. Platforming  of veritable meanwhile maintains familiarities to the previous settings being still super-polished and super-smoothened. Ergo economically improved controller  makes gripping easier with sweeping actions, also advancing responding to commands.",
            img: Xboxdifferences,
        },
        {
            id: 5,
            title: "What Kind of Games Can Be Played on Xbox?",
            text: "Number of optimized participants  are playable within original Xbox  being constructed from the ground to the top lists support technically to the newly advanced features. Improved screen resolutions provide detailed cloud gaming with both all-digital Xboxes and disc-based classes. Availability of backward compatibles allows easy accommodations  and other series are linearly tied to the downloadable versions as well. Headset and headset-jack are available for all varieties including also exclusives and button mapping possibilities. Almost no additional cost is required for game franchises. Playability is maintained in all cases provided by application; meanwhile, specific properties  also  vary with the later. The chance that new-gen applications and technically inflated  issues dispensing are implying comfort with the usage. Updates do not lead to crucial alternates but  to the greatest heats. Introducing additional enhancements to the existing brands  keeps the pulse of up-to-date imperatives.",
            img: Xboxgames,
        }
    ]

    return (
        <div className="ourGames">
            <Helmet>
                <title>Ps5 Pro-Xbox settings-cheap ps4 games</title>
                <meta name="description" content="A list is full of the best and with the most downloads mobile games to play. Join Us!" />
                <link rel="canonical" href="http://hayqgames.com/ps4-ps5-xbox" />
            </Helmet>
            <div className="container">
                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">PS4/5 and Xbox</h2>

                <p className="ourGames__p" data-aos="zoom-in-up" data-aos-duration="3000">
                    Games are an amazing form of entertainment and I'm so glad that you want to bring your game into the world!
                    You're probably wondering what makes our team different. Well, first off our company has more experience with games than any other company out there. Creating a cartoon hero with your appearance indicators is more than possible. Benefit from <Link className="link-redirect" to={{ pathname: '/our-games' }}>Hayq game services</Link> and offerings including PS4/5, Xbox with the up-to-date adjustments.
                </p>

                <div className="ourGames__row">
                    {psData.map(ps => (
                        <div key={ps.id} className="ourGames__col" data-aos="zoom-in-up" data-aos-duration="3000">
                            <h4 className="ourGames__col-title">
                                {ps.title}
                            </h4>
                            <div className="ourGames__col-flex">
                                <p className="ourGames__col-text">
                                    {ps.text}
                                </p>

                                <div className="ourGames__col-img">
                                    <img src={ps.img} alt="" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Ps
