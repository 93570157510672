import React from 'react';
import { Helmet } from "react-helmet";
import './OurGames.scss';
import { Link } from 'react-router-dom';
import apple from '../../../assets/img/apple.png'
import google from '../../../assets/img/google.png'
import { FaYoutube } from 'react-icons/fa';
import { useContext } from 'react';
import { Context } from '../../../index';
import { sanitize } from 'dompurify'

function OurGames() {
    const {store} = useContext(Context)
    const hayqGamesData = store.getState().games.hayqGamesNow
    const soonData = store.getState().games.hayqGamesSoon
   

    

    return (
        <div className="ourGames">
            <Helmet>
                <title>Our Games</title>
                <meta name="description" content="Find your new favorite game app for Android and iPhone. Read about ratings, watch the trailer and start gaming." />
                <link rel="canonical" href="http://hayqgames.com/our-games" />
            </Helmet>
            <div className="container">
                <h1 className="title" data-aos="zoom-out-up" data-aos-duration="2000">Our Games</h1>

                <div className="ourGames__box" data-aos="zoom-in-up" data-aos-duration="3000">
                    {hayqGamesData.map(hayqGames => (
                        <div key={hayqGames.id} className="ourGames__card marginLeft">
                            <h3 className="ourGames__card-title">
                                {hayqGames.title}
                            </h3>

                            <div className="ourGames__card-logo">
                                <img src={hayqGames.logo} alt="logo" />
                            </div>

                            <div className="ourGames__card-links">
                                <Link to="" target="_blank"><img className="" src={apple} alt="appStore" /></Link>
                                <Link to="" target="_blank"><img className="" src={google} alt="googleStore" /></Link>
                            </div>

                            <div className="ourGames__card-game">
                                <img src={hayqGames.gameplay} alt="gameplay" />

                                <div className="ourGames__card-description">
                                    <p key={`${hayqGames.game_description}games`} dangerouslySetInnerHTML={{__html: sanitize(hayqGames.game_description.replace(/(?:\r\n|\r|\n)/g, '<br>'))}}></p>
                                </div>
                            </div>

                            <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="youtube-icon" /></a>
                        </div>
                    ))}
                </div>

                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">Cooming Soon</h2>
                <div className="ourGames__box box_center" data-aos="zoom-in-up" data-aos-duration="3000">
                    {soonData.map(soon => (
                        <div key={soon.id} className="ourGames__card card_margin">
                            <h3 className="ourGames__card-title">
                                {soon.title}
                            </h3>

                            <div className="ourGames__card-logo">
                                <img src={soon.logo} alt="logo" />
                            </div>

                            <div className="ourGames__card-game">
                                <img src={soon.gameplay} alt="gameplay" />

                                <div className="ourGames__card-description">
                                    <p key={soon.id} dangerouslySetInnerHTML={{__html: sanitize(soon.games_description.replace(/(?:\r\n|\r|\n)/g, '<br>') )}}></p>
                                </div>
                            </div>

                            <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="youtube-icon" /></a>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default OurGames
