

const ymd = () => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDay()
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const seconds = date.getSeconds()
    return new Date(year, month, day, hours, minutes, seconds).toISOString().slice(0,19).replace('T', ' ')
}

export default ymd