


const initialState = {
    
    news:[
        
    ]

}




const newsReducer = (state = initialState,action) => {
    switch(action.type){
        default:
            return state
    }
}


export default newsReducer