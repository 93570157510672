import React from 'react';
import { Helmet } from "react-helmet";
import './CareersPages.scss';
import BD from '../../../../assets/img/backend.png';

function BackEndDeveloper() {

    const backData = [
        {
            id: 'backDevelopers',
            title: "Back-end Developers",
            img_path: BD,
            need: [
                "⦁	As a backend developer, your focus should be on generating a better, more efficient software and a better end-user experience",
                "⦁	You should be well-informed, cooperative, and enthusiastic"
            ],
            responsibilities: [
                "⦁  Gather and evaluate data, procedures, and codes in order to troubleshoot issues and pinpoint opportunities for improvement",
                "⦁	Researching references and resources needed to create the game's assets",
                "⦁	Monitoring industry advancements and trends to come up with fresh ideas for new programs, goods, or services",
                "⦁	Keeping track of data and reporting it to the appropriate persons, such as clients or management",
                "⦁	Attending continuous education and training to stay up to date on best practices, learn new programming languages, and support other team members more effectively",
                "⦁	Assisting with tasks as needed",
            ],
            requirements: [
                "⦁	A bachelor's degree in computer programming, computer science, or a similar discipline may be required, as well as further education or experience",
                "⦁	Knowledge of certain languages and operating systems, such as Java, PHP, or Python, may be required",
                "⦁	In-depth knowledge of the web development cycle, as well as programming methodologies and tools",
                "⦁	Concentrate on increasing efficiency, improving user experience, and streamlining processes",
                "⦁	Outstanding project and time management skills",
                "⦁	Excellent problem-solving abilities as well as verbal and writing communication abilities",
                "⦁	The ability to operate alone or in a group",
                "⦁	Willingness to sit at a desk for long periods of time",
            ],
        },
    ]

    return (
        <div className="careersPage">
            <Helmet>
                <title>Hire back end developers</title>
                <meta name="description" content="We're searching for an analytical, results-oriented back-end developer to debug and enhance current back-end apps." />
                <link rel="canonical" href="http://hayqgames.com/careers/back-end-developer" />
            </Helmet>
            <div className="container">
                {backData.filter(item => item.id).map(item => (
                    <div key={item.id} className="job__card">
                        <div className="job__card-left">
                            <h1 className="job__card-title" data-aos="zoom-out-up" data-aos-duration="2000">{item.title}</h1>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>What we need is:</h3>
                                <div> {item.need.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Back-end Developer Responsibilities:</h3>
                                <div> {item.responsibilities.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Back-end Developer Requirements:</h3>
                                <div> {item.requirements.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                        </div>

                        <div className="job__card-img" data-aos="zoom-in-up" data-aos-duration="3000">
                            <img src={item.img_path} alt="Job-img" />
                        </div>
                    </div >
                ))}

            </div>
        </div>
    )
}

export default BackEndDeveloper
