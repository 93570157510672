import React from 'react';
import './Privacy.scss';

function Privacy() {
    return (
        <div className="privacy">
            <div className="container">
                <h4>1.WHAT INFORMATION DO WE COLLECT?</h4>
                <p>
                    We collect personal information that you voluntarily provide to us when registering at the Sites or Apps,expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites or Apps or otherwise contacting us.
                </p>
                <br />
                <p>The personal information that we collect depends on the context of your interactions with us and the Sites or Apps, the choices you make and the products and features you use. The personal information we collect can include the following:</p>
                <strong>Name and Contact Data.</strong><p>We collect your first and last name, email address, postal address, phone number, and other similar contact data.
                </p>
                <strong>Payment Data.</strong><p>We collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card number), and the security code associated with your payment instrument. All payment data is stored by our payment processor and you should review its privacy policies and contact the payment processor directly to respond to your questions. </p>
                <strong>Information automatically collected</strong>
                <p>In Short: Some information – such as IP address and/or browser and device characteristics – is collected automatically when you visit our Site
                </p>

                <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
                <p>In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.  </p>
                <br />
                <p>We use personal information collected via our Site.</p>
                <p>We use the information we collect or receive:</p>
                <br />
                <ul>
                    <li>To facilitate account creation and logon process.</li>
                    <li>To send you marketing and promotional communications.</li>
                    <li>To send administrative information to you.</li>
                    <li>Fulfill and manage your orders.</li>
                    <li>Deliver targeted advertising to you.We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.  </li>
                    <li>Request Feedback.We may use your information to request feedback and to contact you about your use of our Site.</li>
                </ul>

                <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
                <p>In Short:We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.</p>

                <h4>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h4>
                <p>In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</p>

                <h4>5. DO WE MAKE UPDATES TO THIS POLICY?</h4>
                <p>In Short: Yes, we will update this policy as necessary to stay compliant with relevant laws.</p>
            </div>
        </div>
    )
}

export default Privacy;

