import React from 'react';
import { Helmet } from "react-helmet";
import './CareersPages.scss';
import DA from '../../../../assets/img/animators.png';

function Designer() {

    const designerData = [
        {
            id: 'animators',
            title: "2D/3D Designers and Animators",
            img_path: DA,
            need: [
                "⦁	Knowledge, skills, and abilities: Ability to work independently on numerous tasks at the same time",
                "⦁	Ability to conceptualize and develop graphical projects for presentation",
                "⦁	Ability to take charge of a project and work cooperatively",
                "⦁	Visual, written, and spoken communication abilities are exceptional"
            ],
            responsibilities: [
                "⦁	Create 2D/3D animation and motion graphics content for videos as part of your job responsibilities",
                "⦁	Create product illustration images and layouts",
                "⦁	Create relevant marketing materials for the firm and its clientele by conceptualizing and designing them",
                "⦁	Recognize the needs of the customer and the functional specifications. Design or develop graphics for brochures, displays, logos, banners, posters, info graphics, testimonials, and other commercial or promotional purposes, as well as animation, clips, videos, and GIFs",
                "⦁	Design exhibits, displays, and promotional materials for art or products",
                "⦁	Determine the size and placement of graphic material and copy, as well as the type style and size"
            ],
            requirements: [
                "⦁	A diploma or degree with 1-3 years of graphic/motion graphic design and 2D/3D animation expertise is required as a minimum qualification and significant competency",
                "⦁	After Effects, Lightroom, Premier Pro, Flash, 3DS MAX/MAYA, Adobe Illustrator, Photoshop and InDesign, Coral Draw, and Acrobat are all examples of strong PC/MAC proficiency",
                "⦁	Photography, illustration, layout, typography, logo development, and brand look & feel are all skills that may be learned.",
                "⦁	A optimistic, can-do attitude is required"
            ],
        },
    ]

    return (
        <div className="careersPage">
            <Helmet>
                <title>Video game designers</title>
                <meta name="description" content="We are hiring designers - Opportunity to grow in the popular game industry. Expand your Creative Freedom with Hayq Games company." />
                <link rel="canonical" href="http://hayqgames.com/careers/designer" />
            </Helmet>
            <div className="container">
                {designerData.filter(item => item.id).map(item => (
                    <div key={item.id} className="job__card">
                        <div className="job__card-left">
                            <h1 className="job__card-title" data-aos="zoom-out-up" data-aos-duration="2000">{item.title}</h1>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>What we need is:</h3>
                                <div> {item.need.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>2D/3D Designers and Animators Responsibilities:</h3>
                                <div> {item.responsibilities.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>2D/3D Designers and Animators Requirements:</h3>
                                <div> {item.requirements.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                        </div>

                        <div className="job__card-img" data-aos="zoom-in-up" data-aos-duration="3000">
                            <img src={item.img_path} alt="Job-img" />
                        </div>
                    </div >
                ))}

            </div>
        </div>
    )
}

export default Designer
