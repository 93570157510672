import React, { createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import {initializeApp} from 'firebase/app'
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyD5XZot9LT7JSW8gscHDprUQL0FP97RH6A",
  authDomain: "admin-panel-hayqgames.firebaseapp.com",
  projectId: "admin-panel-hayqgames",
  storageBucket: "admin-panel-hayqgames.appspot.com",
  messagingSenderId: "946927658922",
  appId: "1:946927658922:web:b9b14c1124da37f6192dd3"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app)

export const Context = createContext(null)

ReactDOM.render(
  <React.StrictMode>
    <Context.Provider value={{
      db,
      storage,
      store
    }}>
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
