import React from 'react';
import { Helmet } from "react-helmet";
import Join from './join/Join';
import GrowingStudio from './growingStudio/GrowingStudio';

function Team() {

    return (
        <>
            <Helmet>
                <title>Hayq Games Growing Team</title>
                <meta name="description" content="We are experts in mobile game development and a team where everyone is part of a community." />
                <link rel="canonical" href="http://hayqgames.com/team" />
            </Helmet>
            <Join />
            <GrowingStudio />
        </>
    )
}

export default Team
