import React from 'react';
import './Terms.scss';

function Terms() {
    return (
        <div className="terms">
            <div className="container">
                <p>These terms and conditions regarding your use of the Service constitute a legally binding agreement between you and Hayq Games.</p>

                <h4>PRIVACY</h4>
                <p>
                    We respect your privacy and the use and protection of your Personal Information. Please see our Privacy Policy for important information and disclosures relating to the collection and use of your Personal Information in connection with your use of the Service.
                </p>

                <h4>CHILDREN</h4>
                <p>This Service is offered and made available only to users meeting the minimal legal age to provide their own consent (e.g., at least 13 years of age in the US and at least 13 to 16 years of age in the EU, depending on the country). If you do not meet the minimal legal age, please discontinue using the Service immediately, or if for any reason, you do not agree with all of the terms and conditions contained in this Agreement, please discontinue using the Service immediately. By using or attempting to use the Service, you certify that you are at least the minimum age and meet any other eligibility requirements of the Service.</p>

                <p>The words “use” and/or “using” in this Agreement mean any time an individual (a “user”), directly or indirectly, with or without the aid of a machine or device, does or attempts to access, interact with use, display, view, print or copy from the Service, transmit, receive or exchange data or communicate with the Service, or in any way utilizes, benefits, takes advantage of or interacts with any function, service or feature of the Service, for any purpose whatsoever.</p>
            </div>
        </div>
    )
}

export default Terms;
