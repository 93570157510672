import React from 'react';
import './Preloader.scss';

function Preloader() {

    document.body.onload = function () {
        setTimeout(function () {
            var preloader = document.getElementById('page-preloader');
            // if (!preloader.classList.contains('loaded')) {
                preloader.classList.add('loaded')
            // }
        },2000);
    };

    return (
        <div id="page-preloader" className="preloader">
            <div className="loader"></div>
        </div>
    )
}

export default Preloader;
