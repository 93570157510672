import React, { useContext } from 'react';
import './News.scss'
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { Context } from '../../../index';

function News(props) {
    const {db,storage,store} = useContext(Context)


    const newsListData = store.getState().news.news

    return (
        <>
            <Helmet>
                <title>Hayq Games News</title>
                <meta name="description" content="Read the most authoritative, up-to-date news and headlines from the video games at Hayq Games." />
                <link rel="canonical" href="http://hayqgames.com/news" />
            </Helmet>

            <div className="newsList">
                <div className="container">
                    <h1 className="title" data-aos="zoom-out-up" data-aos-duration="2000">NEWS</h1>
                    <div className='news_width_flex'>
                        <div className="newsList__col">
                            {newsListData.map(newsitem => (
                                <div key={newsitem.id} className="newsList__row" data-aos="zoom-in-up" data-aos-duration="3000">
                                    <div className="newsList__img">
                                        <img src={newsitem.img} alt="Img" />
                                    </div>

                                    <div className="newsList__text">
                                        <h2>{newsitem.title}</h2>

                                        <p>{newsitem.name.slice(0,160)}...</p>

                                        <span className="btn button-underline btn-padding">
                                            <Link
                                                className="link"
                                                to={{
                                                    pathname: 'news/' + newsitem.link,
                                                    // state: {
                                                    //     id: newsitem.id,
                                                    //     title: newsitem.title
                                                    // }
                                                }}>Read More!
                                            </Link>
                                        </span>
                                    </div>
                                </div >
                            ))}
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default News
