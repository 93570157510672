import React from 'react';
import { Link } from 'react-router-dom';
import './Article.scss';

function Article() {

    const articleData = [
        {
            id: 1,
            path: 'ps4-ps5-xbox',
            title: "PS4/5 and Xbox",
            description: "PS4, PlayStation 5 and Xbox One are the most popular game consoles on today's market.",
        },
        {
            id: 2,
            path: 'pc',
            title: "PC",
            description: "PC games are a great way to enjoy some time with friends. With an easy interface, they're perfect when you want something lighthearted and fun!",
        },
        {
            id: 4,
            path: 'ios-and-androids',
            title: "Mobile Games iOS/Android",
            description: "The leading platforms are iOS and Android which have been downloaded billions of times each!",
        },
    ]

    return (
        <article className="second_part">
            <div className="container">
                <div className="head">
                    <h3 data-aos="zoom-out-up" data-aos-duration="2000">
                        A new brand at the gaming market
                    </h3>
                    <p data-aos="zoom-in-up" data-aos-duration="3000">
                        Hayq Games as a part of Hayq Inc. has started its life-long activities in 2017 ever since projecting numerous games of the most pedantic clients. Trust is our most assessed merit due to which the year of 2022 enriches our portfolio with <Link className="link-redirect" to={{ pathname: '/our-games' }}>7 games.</Link>
                        <br />
                        Company’s products expand the factual witnesses are scores of contracts stating lastingness of credence and mutual interest. We underline the presence of qualified and creative stuff ready to invest the needed time to be the desired result deserving the fame of good-service provider. Implementing art in games and the latter in art, we create something to proudly offer to larger audience. More focus on creation, less focus on time-consuming processes.
                    </p>
                </div>
                <div className="card_part">
                    {articleData.map(article => (
                        <div key={article.id} className="card" data-aos="zoom-in-up" data-aos-duration="3000">
                            <h4>
                                <Link
                                    className="link"
                                    to={`/${article.path}`}>
                                    {article.title}
                                </Link>
                            </h4>
                            <p>
                                {article.description}
                            </p>
                            <div className="btn button-border-gradient">
                                <Link
                                    className="link"
                                    to={`/${article.path}`}>
                                    leran more
                                </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </article>

    )
}

export default Article
