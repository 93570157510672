import { combineReducers, createStore } from "redux";
import gamesReducer from './games-reducer'
import newsReducer from "./news-reducer";




const reducers = combineReducers({
    games:gamesReducer,
    news:newsReducer
})

const store = createStore(reducers)

window.store = store

export default store