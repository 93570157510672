import React from 'react';
import { Link } from 'react-router-dom';
import './GrowingStudio.scss';
import GrowingStudioBack from '../../../../assets/img/growingstudio.svg';

function GrowingStudio() {
    return (
        <div className="growingStudio">
            <div className="container">
                <div className="teamabout">
                    <div className="teamabout__box-left" data-aos="zoom-in" data-aos-duration="3000">
                        <h2>We are a fast growing studio.</h2>
                        <img src={GrowingStudioBack} alt="" />
                    </div>

                    <div className="teamabout__box-right" data-aos="zoom-in" data-aos-duration="3000">
                        <p>
                            Hayq Games is a part of Hayq Inc, which started its journey since 2017, in Burbank, CA, USA. Our fast growing studio already has branches in Yerevan (Armenia) and Moscow (Russia).
                            <br />
                            <br />
                            Uniting the most creative and enthusiast specialists from different parts of our planet, Hayq Games is seeking to focus on creating more art games than just a classic understanding of that meaning.
                        </p>

                        <Link
                            className="button"
                            to={{ pathname: "/contacts" }}>
                            Join Us
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GrowingStudio
