

import {useContext, useState} from 'react'
import { doc,updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Context} from '../../index'
import './EditBlog.css'

const EditBlog = (props) => {
    const {db,storage,store} = useContext(Context) 
    const [name,setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.name))
    const [title,setTitle] = useState(props.data.filter(item => item.id === props.id).map(item => item.title))

    const [image,setImage] = useState(null)
    const [mainText,setMainText] = useState(props.data.filter(item => item.id === props.id).map(item => item.mainText))
    const [imageUrl,setImageUrl] = useState(null)
    const [imageUploaded1,setImageUploaded1] = useState(false)
    const [editPath,setEditPath] = useState(props.data.filter(item => item.id === props.id).map(item => item.link))
    const [nameChanged,setNameChanged] = useState(false)
    const [titleChanged,setTitleChanged] = useState(false)
    const [mainTextChanged,setMainTextChanged] = useState(false)
    const [pathChanged,setPathChanged] = useState(false)
    const [imageChanged,setImageChanged] = useState(false)
    const saveEdit = () => {
        
        if (image) {
            const storageRef = ref(storage, '/images/' + image.name)
            uploadBytes(storageRef, image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageRef,image.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }
        if(imageUrl !== null && imageChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                img:imageUrl
            })
            setImageUploaded1(true) 
        }

        if(title !== '' && titleChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                title:title
            })
        }
        if(name !== '' && nameChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                name:name
            })
        }
        if(mainText !== '' && mainTextChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                mainText:mainText
            })
        }
       
        if(editPath !== ''  && pathChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                link:editPath
            })
        }

    }


    return (
        <div className=''>
            <div className='flex_column'>
                <input className='edit_input' value={editPath} placeholder='Edit URL' onChange={(e) => {
                    setEditPath(e.target.value)
                    setPathChanged(true)
                }} />
                <input className='edit_input' value={title} placeholder='Edit Title' onChange={(e) => {
                    setTitle(e.target.value)
                    setTitleChanged(true)
                }} />
                <textarea className='edit_textarea' value={name} rows={'5'} placeholder='Edit Description' onChange={(e) => {
                    setName(e.target.value)
                    setNameChanged(true)
                }} ></textarea>
                <textarea className='edit_textarea' rows={'5'} value={mainText} placeholder='Edit Main text' onChange={(e) => {
                    setMainText(e.target.value)
                    setMainTextChanged(true)
                }} ></textarea>
                <label className='color_white'>Main picture (1200 x 800)</label>
                <input type={'file'} onChange={(e) => {
                    setImage(e.target.files[0])
                    setImageChanged(true)
                }} />
                {imageUploaded1 
                ?
                    <span className='image_uploaded_text'>Image succsesfully uploaded</span> 
                :   
                    <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                }
                <button className='saveButton' onClick={saveEdit}>Save</button>
            </div>
        </div>
    )
}


export default EditBlog