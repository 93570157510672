import React from 'react';
import { Helmet } from "react-helmet";
import './CareersPages.scss';
import GD from '../../../../assets/img/gamedevelopers.png';

function Developer() {

    const developerData = [
        {
            id: 'gameDevelopers',
            title: "Game Developers",
            img_path: GD,
            need: [
                "⦁	Success with design skills",
                "⦁	In-depth software knowledge",
                "⦁	Team-work skills",
                "⦁	The probability of working in a virtual environment",
                "⦁	Enthusiasm!"
            ],
            responsibilities: [
                "⦁	Game concept discussion conducting",
                "⦁	Contribution of   game concept and storyline",
                "⦁	Emerging game scripts and storyboards",
                "⦁	Translating  functional game code true to the requirements",
                "⦁	Writing code for game engine",
                "⦁	Audio and animation design expert",
                "⦁	Ability to create  characters and levels",
                "⦁	Introducing  game milestones and checkpoints",
                "⦁	Fixing bugs and troubls",
                "⦁	Game expansion abilities"
            ],
            requirements: [
                "⦁	Bachelor’s degree in software engineering or related field",
                "⦁	Experience in game designing",
                "⦁	Expert in  C++, Java, and C",
                "⦁	Experience with libraries and APIs",
                "⦁	Strong artistic and technical skills",
                "⦁	Audio and animation design expert",
                "⦁	Ability to create  characters and levels",
                "⦁	The ability to troubleshoot and resolve difficult software and coding issues",
            ]
        },
    ]

    return (
        <div className="careersPage">
            <Helmet>
                <title>Game developers job</title>
                <meta name="description" content="Talented video game developers are central to our team's ongoing success. Join our mobile game publishing company and find your place among us." />
                <link rel="canonical" href="http://hayqgames.com/careers/developer" />
            </Helmet>
            <div className="container">
                {developerData.filter(item => item.id).map(item => (
                    <div key={item.id} className="job__card">
                        <div className="job__card-left">
                            <h1 className="job__card-title" data-aos="zoom-out-up" data-aos-duration="2000">{item.title}</h1>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>What we need is:</h3>
                                <div> {item.need.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>

                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Video Game Developer Responsibilities:</h3>
                                <div> {item.responsibilities.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>

                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Video Game Developer Requirements:</h3>
                                <div> {item.requirements.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>

                        </div>

                        <div className="job__card-img" data-aos="zoom-in-up" data-aos-duration="3000">
                            <img src={item.img_path} alt="Job-img" />
                        </div>
                    </div >
                ))}

            </div>
        </div>
    )
}

export default Developer
