import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './Header.scss';
import logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';
import { FaTwitter } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { AiOutlineLinkedin } from 'react-icons/ai';
import { BiChevronDown } from 'react-icons/bi';

function Header() {

    const { pathname } = useLocation();
    let [header, setHeader] = useState(false);
    let [burgerI, setBurger] = useState('close');
    let [openBurger, setOpenBurger] = useState(false);

    let addSticky = () => {
        let scrollvalue = document.documentElement.scrollTop;
        if (scrollvalue >= 505) {
            setHeader(true);
        }
        else {
            setHeader(false);
        }
    }
    window.addEventListener('scroll', addSticky);

    let handleClick = () => {
        setBurger(burgerI === 'open' ? 'close' : 'open');
        setOpenBurger(!openBurger)
    }

    useEffect(() => {
        setOpenBurger(false);
        setBurger('close')
    }, [pathname]);

    return (
        <div className={header ? "sticky" : "header__main"}>
            <div className="container">
                <header className="header">
                    <nav className="header__nav">
                        <NavLink to={{ pathname: "/" }} exact className="header__logo-title"><img className="header__logo" src={logo} alt="HAYQ Games" />HAYQ Games</NavLink>

                        <ul className={`header__list ${openBurger ? 'header__list-active' : ''}`}>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/' }} exact >Home</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/our-games' }} exact>Our Games</NavLink>
                            </li>
                            <li className="dropdown__li">
                                <div className="header__link">
                                    Platforms <BiChevronDown className="header__link-icon" />
                                </div>

                                {/* Platforms Dropdown */}
                                <ul className="games__dropdown">
                                    <li>
                                        <NavLink activeStyle={{
                                            borderBottom: "2px solid #3390EF"
                                        }} className="header__link" to={{ pathname: '/ps4-ps5-xbox' }} exact>PS4/5 and Xbox</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            borderBottom: "2px solid #3390EF"
                                        }} className="header__link" to={{ pathname: '/ios-and-androids' }} exact>Mobile Games iOS and Android</NavLink>
                                    </li>
                                    <li>
                                        <NavLink activeStyle={{
                                            borderBottom: "2px solid #3390EF"
                                        }} className="header__link" to={{ pathname: '/pc' }} exact>PC</NavLink>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/news' }} exact>News</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/team' }} exact>Team</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/careers' }} exact>Careers</NavLink>
                            </li>
                            <li>
                                <NavLink activeStyle={{
                                    borderBottom: "2px solid #3390EF"
                                }} className="header__link" to={{ pathname: '/contacts' }} exact>Contacts</NavLink>
                            </li>
                        </ul>


                        <div className="header__right">

                            <div className="footer__icons header__icons">
                                <a href="https://twitter.com/GamesHayq" target="/blank"><FaTwitter className="white" /></a>
                                <a href="https://www.linkedin.com/company/hayq-games-studio/about/" target="/blank"><AiOutlineLinkedin className="white" /></a>
                                <a href="https://www.facebook.com/hayqgames" target="/blank"><FaFacebookSquare className="white" /></a>
                                <a href="https://www.instagram.com/hayqgames_studio/" target="/blank"><FaInstagram className="white" /></a>
                                <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="white" /></a>
                            </div>


                            <div className="header__burger" role="button" onClick={handleClick}>
                                <i className={burgerI}></i>
                                <i className={burgerI}></i>
                                <i className={burgerI}></i>
                            </div>
                        </div>
                    </nav>

                    {/* {openSign && <Sign closeSign={setOpenSign} />} */}
                </header>
            </div>
        </div >
    )
}

export default Header;


