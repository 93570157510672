import React, { useContext } from 'react';
import { Helmet } from "react-helmet";
import { Context } from '../../../../index';
import './NewsPages.scss';
import { sanitize } from 'dompurify'
function GamePage(props) {
    const { store } = useContext(Context)


    const eventNews = store.getState().news.news
    const eventData = eventNews.filter(item => item.link === props.match.params.link)

    return (
        <section className="newsPage">
            {eventData.map(item => (
                <>  
                    <Helmet>
                <title>{item.title}</title>
                <meta name="description" content="Games events are becoming popular year by year. The provision of such an opportunity is often unconditionally enhancing the overall process." />
                <link rel="canonical" href="http://hayqgames.com/news/events" />
            </Helmet>
            <div className="container">
                    <div key={item.id} className="newspage__card">
                        <div className='flex_content'>
                            <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">{item.title}</h2>
                            {/* <div className='flex_content'>
                                <div className='content'>
                                    <div className='image_part'>
                                        <img src={item.img}  />
                                    </div>
                                    <div data-aos="zoom-in-up" data-aos-duration="3000" className='text_part'>
                                        <p key={item.key}  data-aos="zoom-in-up" data-aos-duration="3000" dangerouslySetInnerHTML={{__html: sanitize(item.mainText.replace(/(?:\r\n|\r|\n)/g, '<br>'))}}></p>
                                    </div>
                                </div>
                            </div> */}
                            <div className='justify_content'>
                                <div id="container">
                                    <div id="floated" data-aos="zoom-in-up" data-aos-duration="3000">
                                        <img src={item.img} data-aos="zoom-in-up" data-aos-duration="3000" />
                                    </div>
                                    <div data-aos="zoom-in-up" data-aos-duration="3000" className='text_part'>
                                        <p key={item.key}  data-aos="zoom-in-up" data-aos-duration="3000" dangerouslySetInnerHTML={{__html: sanitize(item.mainText.replace(/(?:\r\n|\r|\n)/g, '<br>'))}}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
            </div>
                </>
            ))}
            
        </section>
    )
}

export default GamePage
