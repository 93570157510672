import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import './Careers.scss';

function Careers() {

    const careersData = [
        {
            id: 'gameDevelopers',
            title: "Game Developers",
            description: "Talented video game developer is welcomed to our team! The main things to do are fixing bugs, troubleshooting  and conducting the initial base of the game. Developing storylines, puzzles and game concepts can be included in the overall process of game developer if need comes.",
            link: '/careers/developer'
        },
        {
            id: 'animators',
            title: "2D/3D Designers and Animators",
            description: "The motion graphics and 2D/3D animation requirements for BoostNet and its clients, especially for social media, will be managed by the 2D-3D animation and graphic designer. All 2D/3D animation films, including white sketch-board videos, require direction/ideas, art layouts, and art production assistance. Commercialisation, distribution, client orders, and needs necessitate the creation of social media post creatives, logos, and style templates for collateral, presentation, and corporate branding materials.",
            link: '/careers/designer'
        },
        {
            id: 'gameArtist',
            title: "Game Artists",
            description: "Game Artists create basic sketches and refine them in accordance with the overall style of a video game. They then use these sketches to construct 2D or 3D models under the guidance of the Lead Artist. These aspects are responsible for the world's appearance, atmosphere, and distinct personality.Character design, architecture and landscapes, lighting, modeling, special effects, texturing, and animation are all areas of specialty for Game Artists.",
            link: '/careers/artist'
        },
        {
            id: 'backDevelopers',
            title: "Back-end Developers",
            description: "We're searching for an analytical, results-oriented back-end developer to debug and enhance current back-end apps and processes alongside the rest of the team. The Back-end Developer will apply his or her knowledge of programming languages and tools to examine current codes and industry trends, construct more efficient processes, fix difficulties, and provide a more seamless user experience. Excellent communication, computer, and project management abilities are required.",
            link: '/careers/back-end-developer'
        },
    ]

    return (
        <section className="careers-main">
            <Helmet>
                <title>Hayq Games Careers</title>
                <meta name="description" content="Welcome to the Career Interests Game! This is a game designed to help you match your interests and skills with similar careers." />
                <link rel="canonical" href="http://hayqgames.com/careers" />
            </Helmet>

            <div className="container">
                <h1 className="title" data-aos="zoom-out-up" data-aos-duration="2000">Careers</h1>
                <div className="careers">
                    {careersData.map(careers => (
                        <div key={careers.id} className="careers__card" data-aos="zoom-in-up" data-aos-duration="3000">
                            <h2>
                                {careers.title}
                            </h2>

                            <p>
                                {careers.description}
                            </p>

                            <span className="btn button-underline btn-padding">
                                <Link
                                    className="link"
                                    to={{
                                        pathname: careers.link,
                                    }}>Touch here!
                                </Link>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section >
    )
}

export default Careers
