import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import '../ps/Ps.scss';
import Advantages from '../../../../assets/img/advantages.png';
import Types from '../../../../assets/img/types.png';
import What from '../../../../assets/img/what.png';
import All from '../../../../assets/img/all.png';

function Pc() {

    const pcData = [
        {
            id: 1,
            title: "Why Do We Play PC Games? Advantages of PC Games",
            text: "Sources of brain stimulation comes with a number of PC games implementing audial and visual capacities. Senseless to deny the fact of their training functions players process the complicated series of adjustment eventually making their brain speed higher. The only idea that the latter enhances problem –solving skills creates a definite objective later on helping to grasp the necessity of rules and restrictions in one’s life. Very few of us have figured out the practice of having alternative admitting the reality we often force to overcome. Transmitting peculiarities into realism new PC games create constant communication strengthening social connections. The differentiation is based on age specifications as well serving as a common ground.",
            img: Advantages,
        },
        {
            id: 2,
            title: "Types of PC Games ",
            text: "According to the type we differentiate between  “action”, “fighting” based on the speed and linear or non-linear gameplay. Recognizable with pathways  best PC games of 2021 implied niches of encompassing headings introducing concrete goals and experiences. Whereas the unfamiliar mechanics of best steam games 2022  have emerged a procedural generation. Bringing forth the simulations gaming genres have implied franchises as well. Map-management, shooters, battlegrounds, twitchy-witchy role-plays have substantial elements known as groundbreaking. Table-top gaming is especially known for its strong emphasis on the combat and creative thinking. The procedural generation with interest towards roguelike entertainments offers immersive experiences. Brain-fostering  is especially common with puzzling, point-and-click sub-genre is  widely known within  free PC games.",
            img: Types,
        },
        {
            id: 3,
            title: "What is the Best Way to Get PC games?",
            text: "Stepping into a crusty field of gaming one may face brick-and mortar versions intended the availability of internet and speed that would support playing it. Hence, it is advisable to outline the guidelines for players to come up with the technical regalements.  Offers include digital downloading with occasional suggests of entertainment such as software or books or something else. Ubiquitous gaming has a lot of distributers currently though its structure is mainly monolithic. Often the alternatives have their disadvantages and  it is redeemable for the front stores of offerings.",
            img: What,
        },
        {
            id: 4,
            title: "All You Need to Know",
            text: "Games as a service experiences changes until enhancing its hardware modifications utilizing for special purposes. The industry takes the essential role of current entertainment scopes with its offerings of diverse backgrounds and considering different age groups. Throughout the globe, pastimes can be played with the help of numerous devices introducing additional content and refining the service branches. They not only create new opportunities for developers but also for its audience requiring transactions and distractions-on-demand. Monetized frolics assure the inflow for far-reach companies and supply qulity-centred services worldwide.",
            img: All,
        }
    ]

    return (
        <div className="ourGames">
            <Helmet>
                <title>Big box pc games</title>
                <meta name="description" content="You'll find the best games to play in your browser, as well as our game apps. Learn more..." />
                <link rel="canonical" href="http://hayqgames.com/pc" />
            </Helmet>
            <div className="container">
                <h2 className="title" data-aos="zoom-out-up" data-aos-duration="2000">PC</h2>

                <p className="ourGames__p" data-aos="zoom-in-up" data-aos-duration="3000">
                    Hayq Games is a company that creates games for the world's most powerful computers, including models from AMD and Intel. Our PC <Link className="link-redirect" to={{ pathname: '/our-games' }}>game company</Link> provides service for all your needs! Our Service is your one-stop shop for all of the latest games. Many large video game companies are now contracting with Hayq Games.
                </p>

                <div className="ourGames__row">
                    {pcData.map(pc => (
                        <div key={pc.id} className="ourGames__col" data-aos="zoom-in-up" data-aos-duration="3000">
                            <h4 className="ourGames__col-title">
                                {pc.title}
                            </h4>

                            <div className="ourGames__col-flex">
                                <p className="ourGames__col-text">
                                    {pc.text}
                                </p>

                                <div className="ourGames__col-img">
                                    <img src={pc.img} alt="" />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Pc
