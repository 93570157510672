import React from 'react';
import { Link } from 'react-router-dom';
import './HomeNews.scss';
import Img1 from '../../../../assets/img/gameIndustry.jpg';
import Img2 from '../../../../assets/img/gameEvent.jpg';
import Img3 from '../../../../assets/img/gameTrend.jpg';

function HomeNews() {

    const newsData = [
        {
            id: 1,
            img: Img1,
            title: "HAYQ Games Gaming Industry",
            description: "It is quite easy to conquer all of the challenges in the style and imagery of dreams in the digital world and become the sole winner...",
            button: "News 1",
            link: '/news/our-gaming-industry'
        },
        {
            id: 2,
            img: Img2,
            title: "Games Industry Events in 2022",
            description: "Games events are becoming popular year by year as a loyal participant we never lose the chance to take part and introduce our amuses...",
            button: "News 2",
            link: '/news/gaming-events'
        },
        {
            id: 3,
            img: Img3,
            title: "Gaming Industry Trends 2022",
            description: "The development of the recent years has brought the new culture of eco-system in the scope of the considered experiences...",
            button: "News 3",
            link: '/news/gaming-trends'
        }
    ]

    return (
        <section className="news">
            <div className="container">
                <div className="title_part">
                    <h3>News</h3>
                    <div className="btn button-underline">
                        <Link
                            className="link"
                            to={{ pathname: "/news" }}
                        >See all news & events.
                        </Link>
                    </div>
                </div>
                <div className="news_cards">
                    {newsData.map(news => (
                        <div key={news.id} className="news_card" data-aos="zoom-in-up" data-aos-duration="3000">
                            <div className="img">
                                <img src={news.img} alt="" />
                            </div>
                            <div className="category"> news</div>
                            <h4>
                                <Link
                                    className="link"
                                    to={{ pathname: "/news" }}>
                                    {news.title}
                                </Link>
                            </h4>
                            <p>
                                {news.description}
                            </p>
                            <span className="btn button-underline">
                                <Link
                                    className="link"
                                    to={{ pathname: news.link }}>
                                    {news.button}
                                </Link>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default HomeNews
