import React, { useEffect } from 'react';
import './AnimatedVid.scss';
import vid from '../../../../assets/video/vid.mp4';

function AnimatedVid() {

    useEffect(() => {
        let vid = document.querySelector(".video__card");

        window.addEventListener('scroll', function () {
            vid.classList.toggle("sm100", window.scrollY > 80,);
            vid.classList.toggle("sm200", window.scrollY > 180,);
            vid.classList.toggle("sm300", window.scrollY > 280,);
            vid.classList.toggle("sm400", window.scrollY > 380,);
            vid.classList.toggle("sm500", window.scrollY > 480,);

            if (window.scrollY > 1100) {
                vid.classList.remove("sm100", "sm200", "sm300", "sm400")
            }
        });
    });

    // let zoom = 1;
    // useEffect(() => {
    //     document.addEventListener("wheel", function (e) {
    //         if (e.deltaY > 0) {
    //             document.getElementById('zoomElement').style.transform = `scale(${(zoom -= 0.1)})`;
    //         } else {
    //             document.getElementById('zoomElement').style.transform = `scale(${(zoom += 0.1)})`;
    //         }
    //     });
    // });

    // function updateAnimationImages() {
    //     var ratio = current / imageHeight
    //     var intersectionRatioIndex, intersectionRatioValue, intersectionRatio
    //     var rotateX, rotateXMax, rotateY, rotateYMax, translateX
    //     images.forEach(function (image, index) {
    //         intersectionRatioIndex = windowWidth > 760 ? parseInt(index / 2) : index
    //         intersectionRatioValue = ratio - intersectionRatioIndex
    //         intersectionRatio = Math.max(0, 1 - Math.abs(intersectionRatioValue))
    //         rotateXMax = rotateXMaxList[index]
    //         rotateX = rotateXMax - (rotateXMax * intersectionRatio)
    //         rotateX = rotateX.toFixed(2)
    //         rotateYMax = rotateYMaxList[index]
    //         rotateY = rotateYMax - (rotateYMax * intersectionRatio)
    //         rotateY = rotateY.toFixed(2)
    //         if (windowWidth > 760) {
    //             translateX = translateXMax - (translateXMax * easeOutQuad(intersectionRatio))
    //             translateX = translateX.toFixed(2)
    //         } else {
    //             translateX = 0
    //         }
    //         if (intersectionRatioValue < 0) {
    //             rotateX = -rotateX
    //             rotateY = -rotateY
    //             translateX = index % 2 ? -translateX : 0
    //         } else {
    //             translateX = index % 2 ? 0 : translateX
    //         }
    //         setTransform(image, 'perspective(500px) translateX(' + translateX + 'px) rotateX(' + rotateX + 'deg) rotateY(' + rotateY + 'deg)')
    //     })
    // }
    // export default updateAnimationImages;

    return (
        <div className="vid_part" data-aos="zoom-in-up" data-aos-duration="3000">
            <div className="video__card">
                <video className="video" autoPlay muted loop playsInline>
                    <source src={vid} type='video/mp4' />
                </video>
            </div>
        </div>
    )
}

export default AnimatedVid
