import './AdminNews.css'
import { createRef, useContext, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc, deleteDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
// import idGenerator from '../idGenerator'
// import exapmleImage from '../../assets/img/example.png'
import EditBlog from './EditBlog'

const AdminNews = () => {

    const { db, storage, store } = useContext(Context)
    const blog = store.getState().news.news
    const imageRef = createRef()
    const [divId,setDivId] = useState('')
    const [title, setTitle] = useState('')
    const [name, setName] = useState('')
    const [htmlImage,setHtmlImage] = useState(null)
    const [htmlImageUrl,setHtmlImageUrl] = useState()
    const [paragraph1, setParagraph1] = useState('')
    const [moreContent,setMoreContent] = useState(false)
    const [otherParagraph, setOtherParagraph] = useState('')
    const [file, setFile] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [category, setCategory] = useState('web')
    const [uploaded, setUploaded] = useState(null)
    const [loginInput,setLoginInput] = useState('')
    const [loginData,setLoginData] = useState(false)
    const [blogUrl, setBlogUrl] = useState('')
    const [editBox, setEditBox] = useState(false)
    const [queryValue, setQueryValue] = useState('')
    const date = Date.now()
    let ymdDate = date => date.toISOString().slice(0,19).replace('T', ' ')
    const sortedBlog = blog.sort((a,b) => new Date(a.date) - new Date(b.date))
    const searchBlog = sortedBlog.filter(blog => {
        if(queryValue === ''){
            return blog
        }else if(blog.title.toLowerCase().includes(queryValue.toLowerCase())){
            return blog
        }
    })

    const loginAdmin = () => {
        if(loginInput === "1a@3g7jfasqA"){
            setLoginData(true)
        }
    }

    const addPost = () => {
        if (!file) return
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef, file.name)
                .then(url => {
                    setImageUrl(url)
                })
        }


            if (imageUrl !== null && otherParagraph === '') {
                setDoc(doc(db, 'blog', date + 'id'), {
                    id: date + 'id',
                    key: date + 'key',
                    img: imageUrl,
                    name: name,
                    title: title,
                    date:ymdDate(new Date()),
                    link: blogUrl,
                    mainText: paragraph1,
                }).then(() => {
                    setUploaded(true)
                })
            } else if(imageUrl === null) {
                setUploaded(false)
            }


            if (imageUrl !== null && otherParagraph !== '') {
                setDoc(doc(db, 'blog', date + 'id'), {
                    id: date + 'id',
                    key: date + 'key',
                    date:ymdDate(new Date()),
                    img: imageUrl,
                    name: name,
                    title: title,
                    link: blogUrl,
                    mainText: paragraph1,
                    html: otherParagraph
                }).then(() => {
                    setUploaded(true)
                })
            } else if(imageUrl === null) {
                setUploaded(false)
            }


    }

    const handleChange = (e) => {
        setHtmlImage(e.target.files[0])

        if (htmlImage) {
            const storageRefHtml = ref(storage, '/images/' + htmlImage.name)
            uploadBytes(storageRefHtml, htmlImage).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(storageRefHtml, htmlImage.name)
                .then(url => {
                    setHtmlImageUrl(url)
                })
        }


            

    }

    const deleteImageInfo = () => {
        imageRef.current.value = ''
    }




    return (
        <div>
            {loginData 
            ?
            <div className="main">
            <div className='admin_inputs'>
                {/* <img alt=""  className='example__image'/> */}
                <div className='admin_inputs_column'>
                    
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="URL" value={blogUrl} onChange={(e) => setBlogUrl(e.target.value)} />
                    </div>
                    <div className='admin_input1'>
                        <input type={"text"} placeholder="Title" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} type={"text"} placeholder="Description" value={name} onChange={(e) => setName(e.target.value)} ></textarea>
                    </div>
                    <div className='admin_textarea'>
                        <textarea rows={'5'} placeholder="Main text" value={paragraph1} onChange={(e) => setParagraph1(e.target.value)}></textarea>
                    </div>
                    <div>
                        <input type={'checkbox'} onClick={() => setMoreContent(!moreContent)} />
                        <span className='color_white'>Add more content</span>
                    </div>
                    {moreContent 
                    ?
                    <div className='admin_textarea height_block'>
                        <span className='color_white marginTop'>Take image url</span>
                        <input type={'file'} onChange={handleChange} ref={imageRef}  />
                        <button onClick={deleteImageInfo} className='clear_photo_url_button'>Clear photo url</button>
                        {htmlImageUrl 
                        ?
                        <>
                            <h4 className='color_white url_text'>URL:</h4>
                            <span className='imageUrl'> {htmlImageUrl} </span>
                        </>
                          
                        : 
                        null
                        }
                        
                        <textarea rows={'5'} value={otherParagraph} placeholder={"Html text"} onChange={(e) => setOtherParagraph(e.target.value)}></textarea>
                        <div className='height_div'></div>
                    </div>
                    :   
                        null
                    }

                    <div className="centered_div">
                        <div className='flex_direction_class'>
                                <div className='admin_file_input'>
                                    <label className='color_white'>Main picture (1200 x 800)</label>
                                    <input type='file' onChange={(e) => setFile(e.target.files[0])} />
                                </div>

                                <div className='create_admin_post'>
                                    <button onClick={addPost}>Create Post</button>
                                </div>
                                {uploaded
                                    ?
                                    <div>
                                        <span className='succses_upload'>Succsesfully uploaded</span>
                                    </div>
                                    :
                                    <div>
                                        <span className='reject_upload'>Try again dont uploaded</span>
                                    </div>
                                }
                            <div>
                                <div className='search_blog'>
                                    <input placeholder='Search blog' value={queryValue} onChange={(e) => setQueryValue(e.target.value)} />
                                </div>
                                {searchBlog.map(item => (
                                    <>
                                        <div key={item.id} id={item.id} onClick={(e) => setDivId(e.currentTarget.id)}>
                                            <h3 className='color_white'>{item.title}</h3>
                                            <span className='color_white'>Date: {item.date}</span>
                                            <img src={item.img} className='image_content' />
                                            <span className='color_white'>{item.mainText.slice(0,150)}...</span>
                                            <div className='content_buttons'>
                                                <button className='delete_button' onClick={(e) => {
                                                    setTimeout(() => {
                                                        deleteDoc(doc(db,'blog', divId))
                                                    }, 2000)
                                                }}>Delete</button>
                                                <button onClick={() => setEditBox(!editBox) } className='edit_button'>Edit</button>
                                            </div>
                                            {editBox === true && item.id === divId 
                                                ?
                                                    <EditBlog id={divId}  setEditBox={setEditBox} data={searchBlog} />
                                                :
                                                    null
                                                }
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                

            </div>
        </div>
            :
            <div className='login_block'>
                <div className='login_block_flex'>
                    <h1>Login to admin</h1>
                    <input value={loginInput} onChange={(e) => setLoginInput(e.target.value)} type='password' />
                    <button onClick={loginAdmin} className='login_button'>Login</button>
                </div>
            </div>
            }
            

        </div>
    )
}


export default AdminNews