import Jojo from '../assets/img/jojoLogo.jpg';
import School from '../assets/img/schoolLogo.png';
import Naturevshuman from '../assets/img/naturevshumanLogo.png';
import JojoGame from '../assets/img/jojoGame.png';
import SchoolGame from '../assets/img/schoolGame.png';
import NaturevshumanGame from '../assets/img/naturevshumanGame.png';
import Boomole from '../assets/img/boomoleLogo.png';
import BoomoleGame from '../assets/img/boomoleGame.png';
import KinderGame from '../assets/img/kinderGame.png';
import KinderLogo from '../assets/img/kinderLogo.png';


const initialState = {
    hayqGamesNow: [

    ],
    hayqGamesSoon: [

    ]

}




const gamesReducer = (state = initialState,action) => {
    switch(action.type){
        default:
            return state
    }
}


export default gamesReducer