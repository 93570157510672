import React from 'react';
import { Helmet } from "react-helmet";
import './CareersPages.scss';
import GA from '../../../../assets/img/artists.png';

function Artist() {

    const artistData = [
        {
            id: 'gameArtists',
            title: "Game Artists",
            img_path: GA,
            need: [
                "⦁	ToonBoom Harmony",
                "⦁	CelAction",
                "⦁	Unreal Engine",
                "⦁	Nuke",
                "⦁	3D Studio Max",
                "⦁	Mudbox",
                "⦁	Zbrush",
                "⦁	Substance Painter",
                "⦁	CAD",
                "⦁	Unity 3",
                "⦁	Python",
                "⦁	C++",
                "⦁	Houdini",
                "⦁	Adobe Photoshop, Illustrator, After Effects",
                "⦁	Maya",
                "⦁	Blender",
                "⦁	Quixel",
                "⦁	TVPaint",
            ],
            responsibilities: [
                "⦁	Communicating with lead designers and developers on the game's style and feel of the game",
                "⦁	Researching references and resources needed to create the game's assets",
                "⦁	Sketching concept designs and important visual style aspects",
                "⦁	Creating 3D models of all components in accordance with the game engine's technological limitations • Applying textures to all visual aspects",
                "⦁	Applying lighting and shading effects to all elements, ensuring color, intensity, and illumination levels are consistent",
                "⦁	Characters and animals that are animated",
                "⦁	Adding muzzle flashes, weather effects, particle systems, sparking wires, smoke, haze, billowing dust, steam vents, explosions, and other special effects to surfaces, sceneries, and events",
                "⦁	Designing advertising materials, packaging, posters, and websites for the game's debut ",
                "⦁	Creating sound effects, performing audio mixes, and designing",
                "⦁	Producing prototypes for testing",
                "⦁	Working with motion capture",
                "⦁	Keeping track of production documents",
                "⦁	Advising on new technologies",
                "⦁	Solving creative and technical problems",
                "⦁	Sticking to the production timeline, curating and classifying the asset collection,considering the user experience when designing",
            ],
            requirements: [
                "Many parts of game production are covered by game artists. As their careers advance, people may choose to specialize in a field that appeals to them and utilizes their unique skills.",
                "Because games are created in groups, communication and interpersonal skills are essential for the proper operation of the game development pipeline.",
                "Employers may look for the following skills in Game Artists:",
                "⦁	Strong drawing abilities, ability to conceive and create from abstract concepts",
                "⦁	Game art and animation training",
                "⦁	Knowledge of lighting, perspective, material finishes, and special effects",
                "⦁	Cinematic sense for lighting and shading",
                "⦁	Ability to work from storyboards",
                "⦁	Solid understanding of game genres and current gaming trends",
                "⦁	Advanced understanding of visual language - texture, color, dimension, scale, perspective, shade, composition depth of field, proportion, spatial awareness, etc",
                "⦁	Visual narrative talent",
                "⦁	3D modeling abilities",
                "⦁	Knowledge of 2D and 3D animation, as well as knowledge with animation concepts",
                "⦁	Work procedures that are systematic and logical",
                "⦁	Working knowledge of industry standard software and ongoing professional development",
                "⦁	Team player with the ability to work independently",
                "⦁	Audio file experience",
                "⦁	Ability to generate special effects",
                "⦁	Knowledge of anatomy, biology, layout design, texturing, and asset production",
                "⦁	Ability to receive constructive criticism and deliver adjustments based on feedback",
            ]
        },
    ]

    return (
        <div className="careersPage">
            <Helmet>
                <title>Video game artists</title>
                <meta name="description" content="We are hiring game artists. They are crucial to the process of creating games. Before you dig into the details, here's a quick overview." />
                <link rel="canonical" href="http://hayqgames.com/careers/artist" />
            </Helmet>
            <div className="container">
                {artistData.filter(item => item.id).map(item => (
                    <div key={item.id} className="job__card">
                        <div className="job__card-left">
                            <h1 className="job__card-title" data-aos="zoom-out-up" data-aos-duration="2000">{item.title}</h1>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>What we need is:</h3>
                                <div> {item.need.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Game Artists Responsibilities:</h3>
                                <div> {item.responsibilities.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000">
                                <h3>Game Artists Requirements:</h3>
                                <div> {item.requirements.map(el => (
                                    <p key={`${el}a`}>{el}</p>
                                ))}
                                </div>
                            </div>
                        </div>

                        <div className="job__card-img" data-aos="zoom-in-up" data-aos-duration="3000">
                            <img src={item.img_path} alt="Job-img" />
                        </div>
                    </div >
                ))}

            </div>
        </div>
    )
}

export default Artist
