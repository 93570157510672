import React from 'react';
import './Join.scss';
import JoinGame from '../../../../assets/img/joingame.png';
import JoinGame2 from '../../../../assets/img/joingame2.png';

function Join() {
    return (
        <div className="join_background">
            <div className="container">
                <div className="join">
                    <div className="join__box">
                        <div className="join__box-title" data-aos="zoom-out-up" data-aos-duration="2000">
                            <h2 className="title">Why I <strong>joined</strong> Hayq Games</h2>
                        </div>

                        <p className="join__box-card" data-aos="zoom-in-right" data-aos-duration="3000">
                            Hayq games services offers diversity of products beyond classical consideration. Our creativeness outputs multi-dimensional particulars. We think outside the boxes suggesting games possible to play on PC or mobile (both Android and iPhone). Best steam, PC and mobile games are available with us! In search of best service-provider, subtle eye would find us easily while enumerating desired qualifications within the search line.
                        </p>
                    </div>

                    <div className="join__box-img" data-aos="zoom-in-left" data-aos-duration="3000">
                        <img src={JoinGame} alt="" />
                    </div>
                </div>

                <div className="join">
                    <div className="join__box-img" data-aos="zoom-in-right" data-aos-duration="3000">
                        <img src={JoinGame2} alt="" />
                    </div>

                    <div className="join__box">
                        <p className="join__box-card" data-aos="zoom-in-left" data-aos-duration="3000">
                            Having quality as an axis to our performance we have expanded throughout the time grasping the attention of users in Yerevan (Armenia) and Moscow (Russia). Serving as the embodiment of a bulk of creations by designers (game artists), programmers, data analysts and other team members, we cook the “dish” bringing it forth with you. Hayq Games is not only dispensing check overs but also takes into consideration one’s preferences. The technical part is carried out by professional and it is possible to adjust your regards on the rest of the work to be done, thus wishing to make your childhood friend a hero of your company’s gaming is not a hard ordeal any more. Creating a cartoon hero with your appearance indicators is more than possible. Benefit from Hayq Games services and offerings including PS4/5, Xbox with the up-to-date adjustments.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Join
