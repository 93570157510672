import { useContext, useRef, useState } from 'react'
import { Context } from '../../index'
import { setDoc, doc,deleteDoc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Link} from 'react-router-dom'
import apple from '../../assets/img/apple.png'
import google from '../../assets/img/google.png'
import { FaYoutube } from 'react-icons/fa';
import './Admin.css'
import Edit from './Edit'
import EditSoonGames from './EditSoonGames'
import ymd from '../utils/ymd'



const Admin = () => {
    const {store,db,storage} = useContext(Context)
    const [title,setTitle] = useState('')
    const [title2,setTitle2] = useState('')
    const [file,setFile] = useState(null)
    const [file3,setFile3] = useState(null)
    const [file4,setFile4] = useState(null)
    const [contentText,setContentText] = useState('')
    const [contentText2,setContentText2] = useState('')
    const [fileUrl,setFileUrl] = useState(null)
    const [file2,setFile2] = useState(null)
    const [fileUrl2,setFileUrl2] = useState(null)
    const [uploaded,setUploaded] = useState(null)
    const [uploaded2,setUploaded2] = useState(null)
    const [divId,setDivId] = useState(null)
    const [query,setQuery] = useState('')
    const [querySoon,setQuerySoon] = useState('')
    const [editBox,setEditBox] = useState(false)
    const [login,setLogin] = useState(false)
    const [loginInput,setLoginInput] = useState('')
    let oldValue = useRef()
    const hayqGamesNow = store.getState().games.hayqGamesNow
    const hayqGamesSoon = store.getState().games.hayqGamesSoon

    // console.log(ymd.toISOString().slice(0,19).replace('T', ' '))
    hayqGamesNow.sort((a,b) => new Date(a.date) - new Date(b.date))
    hayqGamesSoon.sort((a,b) => new Date(a.date) - new Date(b.date))

    const searchedDate =  hayqGamesNow.filter(blog => {
        if(query === ''){
            return blog
        }else if(blog.title.toLowerCase().includes(query.toLowerCase())){
            return blog
        }
    })
    const searchedSoon =  hayqGamesSoon.filter(blog => {
        if(querySoon === ''){
            return blog
        }else if(blog.title.toLowerCase().includes(querySoon.toLowerCase())){
            return blog
        }
    })

    
    const DateNow = Date.now()
    
    const addComingGame = () => {
        const storageRef = ref(storage, '/images/' + file3.name)
        uploadBytes(storageRef, file3).then(response => {
            console.log('file1 uploaded')
        })
        if (file3) {
            getDownloadURL(storageRef,file3.name)
                .then(url => {
                    setFileUrl(url)
                })
        }
        const storageRef2 = ref(storage, '/images/' + file4.name)
        uploadBytes(storageRef2, file4).then(response => {
            console.log('file1 uploaded')
        })
        if (file4) {
            getDownloadURL(storageRef2,file4.name)
                .then(url => {
                    setFileUrl2(url)
                })
        }

        if(fileUrl !== null && fileUrl2 !== null){
            setDoc(doc(db,'comingSoon', DateNow + 'id'), {
                id:DateNow + 'id',
                title:title2,
                logo:fileUrl,
                gameplay:fileUrl2,
                games_description: contentText2,
                date: ymd()

            }).then(setUploaded2(true))
        }
        

    }
    const addGame = () => {
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef,file.name)
                .then(url => {
                    setFileUrl(url)
                })
        }
        const storageRef2 = ref(storage, '/images/' + file2.name)
        uploadBytes(storageRef2, file2).then(response => {
            console.log('file1 uploaded')
        })
        if (file2) {
            getDownloadURL(storageRef2,file2.name)
                .then(url => {
                    setFileUrl2(url)
                })
        }

        if(fileUrl !== null && fileUrl2 !== null){
            setDoc(doc(db,'games', DateNow + 'id'), {
                id:DateNow + 'id',
                title:title,
                logo:fileUrl,
                gameplay:fileUrl2,
                game_description: contentText,
                date:ymd()

            }).then(setUploaded(true))
        }
        

    }

    const loginData = () => {
        if(loginInput === 'Forbes2000@!'){
            setLogin(true)
        }
    }


    return(
        <>
            {login == true 
            ?
            <div className='main'>
                <div className='inputs_flex'>
                    <div className='input_flex_direction'>
                        <h2 className='color_white'>Our games</h2>
                        <div className='adm_input'>
                            <input placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                        </div>
                        <div className='adm_input'>
                            <textarea placeholder='Description' value={contentText} onChange={(e) => setContentText(e.target.value)}></textarea>
                        </div>
                        <div className='adm_input'>
                            <label className='color_white'>Logo</label>
                            <input type={'file'} className='marginLeft' onChange={(e) => setFile(e.target.files[0])} />
                        </div>
                        <div className='adm_input'>
                            <label className='color_white'>Main image</label>
                            <input type={'file'} className='marginLeft' onChange={(e) => setFile2(e.target.files[0])} />
                        </div>
                        <div className='add_blog_div'>
                            <button className='add_blog cursor_pointer' onClick={addGame}>Add Game</button>
                        </div>
                        {uploaded 
                        ?
                        <span className='succsesfully'>Image succsesfuly uploaded</span> 
                        :
                        <span className='try_again_text'>Try again upload image</span>
                        }
                    </div>
                    <div className='input_flex_direction'>
                    <h2 className='color_white'>Coming soon games</h2>
                        <div className='adm_input'>
                            <input placeholder='Title' value={title2} onChange={(e) => setTitle2(e.target.value)} />
                        </div>
                        <div className='adm_input'>
                            <textarea placeholder='Description' value={contentText2} onChange={(e) => setContentText2(e.target.value)}></textarea>
                        </div>
                        <div className='adm_input'>
                            <label className='color_white'>Logo</label>
                            <input type={'file'} className='marginLeft' onChange={(e) => setFile3(e.target.files[0])} />
                        </div>
                        <div className='adm_input'>
                            <label className='color_white'>Main image</label>
                            <input className='marginLeft' type={'file'} onChange={(e) => setFile4(e.target.files[0])} />
                        </div>
                        <div className='add_blog_div'>
                            <button className='add_blog cursor_pointer' onClick={addComingGame}>Add Game</button>
                        </div>
                        {uploaded2
                        ?
                        <span className='succsesfully'>Image succsesfuly uploaded</span> 
                        :
                        <span className='try_again_text'>Try again upload image</span>
                        }
                    </div>
                </div>
                <div className='content_flex'>
                    <div className='justify'>
                        <div className='content_flex_direction'>
                            <h2 className='color_white'>Our games</h2>
                            <input value={query} onChange={(e) => setQuery(e.target.value)} placeholder='Search game' />
                            {searchedDate.map(hayqGames => (
                                <div key={hayqGames.id} className="ourGames__card" id={hayqGames.id} onMouseEnter={(e) => {
                                    setDivId(e.currentTarget.id)
                                }}>
                                    <h3 className="ourGames__card-title color_white">
                                        {hayqGames.title}
                                    </h3>

                                    <div className="ourGames__card-logo">
                                        <img src={hayqGames.logo} alt="logo" />
                                    </div>


                                    <div className="ourGames__card-game">

                                        <div className="ourGames__card-description"> {
                                            <p key={`${hayqGames.game_description}games`} className='color_white'>{hayqGames.game_description}</p>}
                                        </div>
                                    </div>

                                    <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="youtube-icon" /></a><br />
                                    <button className='cursor_pointer' onClick={() => {
                                        deleteDoc(doc(db,'games', divId))
                                    }}>Delete</button>
                                    <button className='edit_button cursor_pointer' onClick={() => setEditBox(!editBox)}>Edit</button>
                                    {editBox === true && hayqGames.id === divId 
                                    ?
                                    <Edit id={divId} data={hayqGamesNow} /> 
                                    :
                                    null
                                    }
                                </div>
                            ))}
                        </div>
                        <div className='content_flex_direction' onMouseEnter={(e) => {
                            setDivId(e.currentTarget.id)
                        }}>
                            <h2 className='color_white'>Coming Soon</h2>
                            <input value={querySoon} onChange={(e) => setQuerySoon(e.target.value)} placeholder='Search game' />
                            {searchedSoon.map(hayqGames => (
                                <div key={hayqGames.id} className="ourGames__card" id={hayqGames.id} onMouseEnter={(e) => {
                                    setDivId(e.currentTarget.id)
                                }}>
                                    <h3 className="ourGames__card-title color_white">
                                        {hayqGames.title}
                                    </h3>

                                    <div className="ourGames__card-logo">
                                        <img src={hayqGames.logo} alt="logo" />
                                    </div>


                                    <div className="ourGames__card-game">

                                        <div className="ourGames__card-description"> {
                                            <p key={`${hayqGames.games_description}games`} className='color_white'>{hayqGames.games_description}</p>}
                                        </div>
                                    </div>

                                    <a href="https://www.youtube.com/channel/UCQ2FZTeP5XO2QVH27FP-zEQ" target="/blank"><FaYoutube className="youtube-icon" /></a><br />
                                    <button className='cursor_pointer' onClick={() => {
                                        deleteDoc(doc(db,'comingSoon',divId))
                                    }}>Delete</button>
                                    <button onClick={() => setEditBox(!editBox)} className='edit_button cursor_pointer'>Edit</button>
                                    {editBox === true && hayqGames.id === divId 
                                    ?
                                    <EditSoonGames id={divId} data={hayqGamesSoon} /> 
                                    :
                                    null
                                    }
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            :
                <div className='main'>
                    <div className='login_input_flex'>
                        <div className='login_input_flex_direction'>
                            <h3>Login</h3>
                            <input value={loginInput} onChange={(e) => setLoginInput(e.target.value)} type='password' />
                            <button onClick={loginData}>Login</button>
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}



export default Admin