import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import Preloader from './components/preloader/Preloader';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import Header from './components/header/Header';
import Home from './components/pages/home/Home';
import OurGames from './components/pages/ourGames/OurGames';
import Pc from './components/pages/platforms/pc/Pc';
import MobileGames from './components/pages/platforms/mobileGames/MobileGames';
import Ps from './components/pages/platforms/ps/Ps';
import Team from './components/pages/team/Team';
import Contacts from './components/pages/contacts/Contacts';
import Careers from './components/pages/careers/Careers';
import Developer from './components/pages/careers/careersPages/Developer';
import Designer from './components/pages/careers/careersPages/Designer';
import Artist from './components/pages/careers/careersPages/Artist';
import BackEndDeveloper from './components/pages/careers/careersPages/BackEndDeveloper';
import Footer from './components/footer/Footer';
import Terms from './components/pages/terms/Terms';
import Privacy from './components/pages/privacy/Privacy';
import Admin from './components/admin/Admin';
import {Context} from './index'
import { useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useState } from 'react';
import AdminNews from './components/admin_news/AdminNews';
import GamePage from './components/pages/news/newsPages/GamePage';
import News from './components/pages/news/News';

function App() {
  const {db,store} = useContext(Context)
  const news = collection(db,'blog')
  const comingSoon = collection(db,'comingSoon')
  const hayqGamesNow = collection(db,'games')
  const newsStore = store.getState().news.news
  const gamesStore = store.getState().games.hayqGamesNow
  const comingSoonStore = store.getState().games.hayqGamesSoon
  const [hayqGamesNowState,setHayqGamesNowState] = useState(null)
  const [hayqGamesSoon,sethayqGamesSoon] = useState(null)
  const [newsState,setNewsState] = useState(null)
  const [comingGames,setComingGames] = useState(null)

  useEffect(() => {
    Aos.init();
    getDocs(news).then(response => {
      response.docs.map(doc => {
        setNewsState([...newsStore, newsStore.unshift(doc.data())])
      })
    })
    getDocs(hayqGamesNow).then(response => {
      response.docs.map(doc => {
        setHayqGamesNowState([...gamesStore, gamesStore.unshift(doc.data())])
      })
    })
    getDocs(comingSoon).then(response => {
      response.docs.map(doc => {
        setComingGames([...comingSoonStore, comingSoonStore.unshift(doc.data())])
      })
    })

  }, [])
  return (
    <>
      <Router>
        <Preloader />
        <Header />
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/our-games' component={OurGames} />
          <Route exact path='/admin' component={Admin} />
          <Route exact path='/admin/news' component={AdminNews} />
          <Route exact path='/ps4-ps5-xbox' component={Ps} />
          <Route exact path='/ios-and-androids' component={MobileGames} />
          <Route exact path='/pc' component={Pc} />
          <Route exact path='/team' component={Team} />
          <Route exact path='/news' component={News} />
          <Route exact path='/news/:link' component={GamePage} />
          <Route exact path='/contacts' component={Contacts} />
          <Route exact path='/careers' component={Careers} />
          <Route exact path='/careers/developer' component={Developer} />
          <Route exact path='/careers/designer' component={Designer} />
          <Route exact path='/careers/artist' component={Artist} />
          <Route exact path='/careers/back-end-developer' component={BackEndDeveloper} />
          <Route exact path='/terms' component={Terms} />
          <Route exact path='/privacy' component={Privacy} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}
export default App;
