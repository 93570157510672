import React from 'react';
import { Link } from 'react-router-dom';
import './Section.scss';

function Section() {
    return (
        <section className="container__back-blue">
            <div className="container">
                <div className="section">
                    <h2 data-aos="zoom-out-up" data-aos-duration="2000">Every feature included</h2>

                    <div className="section__boxes" data-aos="zoom-in-up" data-aos-duration="3000">
                        <div className="section__link jojo" >
                            <span>Bear Jojo</span>

                            <Link to="/our-games"><h4>Download now</h4></Link>

                            <p>Meet Bear Jojo in our Funniest Ever Arcade Cartoon game.</p>
                        </div>

                        <div className="section__link school">
                            <span>School Hero</span>

                            <Link to="/our-games"><h4>Download now</h4></Link>

                            <p>Let’s help Kevin and Melanie to become school heroes!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Section
