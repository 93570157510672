import {useContext, useState} from 'react'
import { doc,updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Context} from '../../index'
import './Edit.css'

const Edit = (props) => {
    const {db,storage} = useContext(Context) 
    const [paragraph,setParagraph] = useState(props.data.filter(item => item.id === props.id).map(item => item.game_description ? item.game_description : item.games_description))
    const [name,setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.title))
    const [image,setImage] = useState(null)
    const [image2,setImage2] = useState(null)

    const [imageUrl,setImageUrl] = useState(null)
    const [imageUrl2,setImageUrl2] = useState(null)
    const [imageUploaded1,setImageUploaded1] = useState(false)
    const [imageUploaded2,setImageUploaded2] = useState(false)
    const [paragraphChanged,setParagraphChanged] = useState(false)
    const [nameChanged,setNameChanged] = useState(false)


    const saveEdit = () => {
        if (image) {
            uploadBytes(ref(storage, '/images/' + image.name), image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(ref(storage, '/images/' + image.name),image.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }
        if (image2) {
            uploadBytes(ref(storage, '/images/' + image2.name), image2).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(ref(storage, '/images/' + image2.name),image2.name)
                .then(url => {
                    setImageUrl2(url)
                    console.log('file 1 url -' + imageUrl2)
                })
        }

        if(paragraph !== '' && paragraphChanged === true){
            updateDoc(doc(db,'games', props.id), {
                game_description: paragraph
            })
        }

        if(name !== '' && nameChanged === true){
            updateDoc(doc(db,'games', props.id), {
                title: name,
            })
        }
        if(imageUrl !== null){
            updateDoc(doc(db,'games', props.id), {
                logo:imageUrl,
            }).then(() => {
                setImageUploaded1(true)
            })
            
        }else{
            setImageUploaded1(false)
        }
        if(imageUrl2 !== null){
            updateDoc(doc(db,'games', props.id), {
                gameplay:imageUrl2,
            }).then(() => {
                setImageUploaded2(true)
            })
            
        }else{
            setImageUploaded2(false)
        }


    }

    return (
        <div className=''>
            <div className='flex_column'>
                {props.data.filter(cont => cont.id === props.id).map(item => (
                    <>

                        <input value={name} className='edit_input' placeholder='Edit Title' onChange={(e) => {
                            setNameChanged(true)
                            setName(e.target.value)
                        }} />
                        <textarea value={paragraph} placeholder='Edit content text' className='second_input' onChange={(e) => {
                            setParagraphChanged(true)
                            setParagraph(e.target.value)
                        }} rows={"5"} cols="67" ></textarea>
                        <label className='color_white'>Logo image</label>
                        <input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                        
                        {imageUploaded1
                            ?
                            <span className='image_uploaded_text'>Image succsesfully uploaded</span>
                            :
                            <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                        }
                        <label className='color_white'>Main image</label>
                        <input type={'file'} onChange={(e) => setImage2(e.target.files[0])} />
                        {imageUploaded2
                            ?
                            <span className='image_uploaded_text'>Image succsesfully uploaded</span>
                            :
                            <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                        }

                        <div className='save_button'>
                        <button className='saveButton' onClick={saveEdit}>Save</button>
                        </div>
                    </>
                ))}

            </div>
        </div>
    )
}


export default Edit